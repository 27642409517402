import { setFeatureFlags, type FeatureFlag } from '@store/feature-flags';

export function FeatureFlagSetter({
	featureFlags,
}: {
	featureFlags: FeatureFlag[];
}) {
	setFeatureFlags(featureFlags);
	return null;
}
