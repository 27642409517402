import { atom, computed } from 'nanostores';

export const $featureFlags = atom<Set<FeatureFlag>>(new Set());

export const featureFlagIds = [] as const;
export type FeatureFlag = (typeof featureFlagIds)[number];

export function setFeatureFlags(featureFlags: FeatureFlag[]) {
	const currentFeatureFlags = new Set($featureFlags.get());
	featureFlags.forEach((flag) => {
		currentFeatureFlags.add(flag);
	});
	$featureFlags.set(currentFeatureFlags);
}

export function resetFeatureFlags() {
	$featureFlags.set(new Set());
}

export const $isFeatureFlagSet = (featureFlag: FeatureFlag) =>
	computed($featureFlags, (flags) => flags.has(featureFlag));
